export const globalHandleMessage = (
    event: any,
    addMessage: any,
    activeUsers: any,
    updateMessageData: any,
    cancelAlarmActiveHandler: any,
    reqPolActive: any,
    requestPoliceDefault: any,
    handleIncidentEvent: any,
) => {
    try {
        if (event.message.type) {
            const text = event.message.payload.body || event.message || '';
            const messageTimeIs = event.timetoken;
            const typeMsg = event.message.payload.event_type;
            const publisherId = event.message.payload.sender?.token;
            const publisherName = event.message.payload.sender?.name;
            const endUserEta = event.message?.payload?.event_data?.end_user_eta;
            const peopleAtTheLocation =
                event.message?.payload?.event_data?.end_user_people_location;
            const evidenceOfPropertyDamage =
                event.message?.payload?.event_data?.end_user_property_damage;
            const concernForSomeonePersonalSafety =
                event.message?.payload?.event_data?.end_user_concern_for_safety;
            const unsureOfWhatCausedTheAlarm =
                event.message?.payload?.event_data?.end_user_unsure_of_cause;
            const incidentEventType = event.message.payload.type;
            const timeExtended = event.message.payload.time_extended;

            if (
                ['cs001', 'cs999', 'cso001', 'cso999', 'cso005', 'eu005', 'eu006'].includes(
                    incidentEventType,
                )
            ) {
                handleIncidentEvent(incidentEventType);
            }

            if (typeMsg === 'eu001') {
                activeUsers((pre: any) => [
                    ...pre,
                    event.message.payload.sender,
                ]);
            }

            if (typeMsg === 'eu005') {
                cancelAlarmActiveHandler();
            }

            if (typeMsg === 'eu006') {
                reqPolActive();
                requestPoliceDefault();
            }

             if ('clips' in event.message.payload) {
                const data = processVideoClips(event, addMessage);
                addMessage((preVdo: any) => [data, ...preVdo]);
            }

            updateMessageData({
                type: typeMsg,
                msgTime: messageTimeIs,
                timeExtended: timeExtended,
            });

            addMessage((messages: any) => [
                ...messages,
                {
                    message: text,
                    type: typeMsg,
                    publisherId: publisherId,
                    msgTime: messageTimeIs,
                    publisherName: publisherName,
                    endUserEta,
                    peopleAtTheLocation,
                    evidenceOfPropertyDamage,
                    concernForSomeonePersonalSafety,
                    unsureOfWhatCausedTheAlarm,
                },
            ]);
        }
    } catch (error) {
        console.error('An error message:', error);
    }
};

export function timeFormatter(timeToken: any) {
    const unixTimestamp = Math.floor(timeToken / 10000);
    const dateObject = new Date(unixTimestamp);
    dateObject.setSeconds(0);
    const formattedDate = dateObject.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
    });

    return formattedDate;
}

export function dateTmeConvert(receiveTime: any) {
    const unixTimestamp = Math.floor(receiveTime / 10000);
    return new Date(unixTimestamp);
}

export function renderChatMessage(
    message: any,
    tokenId: string,
    {
        renderAdtInformationMessage,
        renderRegularMessage,
        renderCancelAlarmTrigger,
        renderIsCancelAlarmActive,
        renderRequestPoliceTrigger,
        renderJoinUser,
        renderTimeAddMsg,
        videoClipsRender,
    }: {
        renderAdtInformationMessage: () => any;
        renderRegularMessage: () => any;
        renderCancelAlarmTrigger: () => any;
        renderIsCancelAlarmActive: () => any;
        renderRequestPoliceTrigger: () => any;
        renderJoinUser: () => any;
        renderTimeAddMsg: () => any;
        videoClipsRender: () => any;
    },
) {
    if (message.type === 'eu001') {
        return renderJoinUser();
    } else if (message.type === 'eu005') {
        return renderIsCancelAlarmActive();
    } else if (message.type === 'eu006') {
        return renderAdtInformationMessage();
    } else if (message.type === 'eu016') {
        return renderTimeAddMsg();
    } else if (
        message.type === 'EVENT_MESSAGE' ||
        message.type === 'TIME_EXPIRED'
    ) {
        return renderRegularMessage();
    } else if (message.cancelAlarmTrigger) {
        return renderCancelAlarmTrigger();
    } else if (message.requestPoliceTrigger) {
        return renderRequestPoliceTrigger();
    } else if (message.type === 'VIDEO_CLIPS') {
        return videoClipsRender();
    } else {
        return null;
    }
}

export const processMessage = (m: any, activeUsers: any) => {
    if (m.message?.payload && m.message?.type && m.message.payload?.sender) {
        const publisherId = m.message.payload.sender.token;
        const publisherName = m.message.payload.sender.name;
        const message = m.message.payload.body;
        let type = m.message.type;
        const eventType = m.message.payload?.event_type;
        const msgTime = m.timetoken;
        const endUserEta = m.message?.payload?.event_data?.end_user_eta;
        const peopleAtTheLocation =
            m.message?.payload?.event_data?.end_user_people_location;
        const evidenceOfPropertyDamage =
            m.message?.payload?.event_data?.end_user_property_damage;
        const concernForSomeonePersonalSafety =
            m.message?.payload?.event_data?.end_user_concern_for_safety;
        const unsureOfWhatCausedTheAlarm =
            m.message?.payload?.event_data?.end_user_unsure_of_cause;

        if (type === 'eu001') {
            activeUsers((pre: any) => [...pre, m.message.payload.sender]);
        }

        if (eventType === 'TIME_EXPIRED') {
            type = eventType;
        }

        return {
            message,
            type,
            msgTime,
            publisherId,
            publisherName,
            endUserEta,
            peopleAtTheLocation,
            evidenceOfPropertyDamage,
            concernForSomeonePersonalSafety,
            unsureOfWhatCausedTheAlarm,
        };
    }

    return null;
};

export const processVideoClips = (m: any, addMessage: any) => {
    const type = 'VIDEO_CLIPS';
    const clips = m.message.payload.clips;
    const msgTime = m.timetoken;
    const isFirstClip = m.message.isFirstClip;

    return { type, clips, msgTime, isFirstClip };
};

export const parsePayloadFromJWT = (token: string) => {
    const encryptedPayload = token.split('.')[1];
    return JSON.parse(atob(encryptedPayload));
};

export function utcDate(date: any) {
    return new Date(
        Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds(),
        ),
    );
}
